.nosotros{
    color: var(--gray-color);
    height: 90vh;
    /* transform: skewX(-20deg); */
}
.nosotros h1{
    font-size: 3vh;
    /* padding-bottom: 10vh; */
}

.nosotros h3{
    font-size: 3vh;
}
/* .nosotros h4{
    font-size: 1.5vh;
} */

@media only screen and (max-device-width: 980px){
    .nosotros{
        position:relative;
        display:block;
    }
}