
.nav-container{
    position: absolute;
    /* bottom: 5vh; */
    z-index: 1;
    width: 100%;
    box-shadow:   5px 5px 15px rgb(0 0 0 / 70%);
}

nav ul {
    list-style-type: none;
    overflow: hidden;
    background-color: var(--gray-color);
    width: 100%;
}

nav ul li{
    float: left;
    min-width: 10%;
}

nav ul li a {
    display: block;
    color: rgb(255, 255, 255);
    text-align: center;
    text-decoration: none;
    padding: 3.5vh 3vh;
    font-size: 3vh;
}

nav ul li a:hover{
  text-decoration: none;
  color: white;
  background-color: var(--orange-color);
  cursor: pointer;
}
.nav{
  background-color: var(--gray-color);
}
.nav_active {
    background-color: var(--orange-color);
} 

@keyframes navbot {
    0% { bottom: 5vh;}
    100% { bottom: 0vh;}
}
@keyframes navtop {
    0% { bottom: 0vh;}
    100% { bottom: 5vh;}
}