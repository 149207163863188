.servicios{
    color: var(--gray-color);
    /* position: absolute; */
    /* display: none; */
    /* top: 10vh; */
    /* left: 8vw; */
    background-color: white;
    width: 70vw;
    height: 90vh;
    transform: skewX(-20deg);
}
.servicios h1{
    font-size: 3vh;
    padding-bottom: 10vh;
    text-align: center;
}
.parrafo-servicios{
    width: 40vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.servicios h3{
    font-size: 2vh;
    padding-bottom: 3.5vh;
}


@media only screen and (max-device-width: 980px){
    .servicios{
        position: relative;
        display: block;
    }
}