.cover {
    font-family: "AndorRegular";
    width: 100%;
    height: 100vh;
    position: absolute;
    overflow: hidden;
} 

.cover-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
    filter: opacity(60%);
}
.cover-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
    /* filter: opacity(90%); */
    filter:brightness(60%);
}


@media only screen and (max-device-width: 980px){

    .cover{
      height: 93.5vh;
      font-size: 12px;
    }

  }