
.white-inicio{
    background-color: white;
    position: absolute;
    /* top: 0; */
    width: 60vw;
    height: 90vh;
    transform: skewX(-45deg);
    /* animation: homein 2s; */
    left: -25vw;
    
}

.orange-triangule{
    background-color: var(--orange-color);
    /* position: absolute; */
    top: 0;
    width: 20vw;
    height: 100%;
    /* transform: skewX(-45deg); */
    left: -10vw;
}

.cover-logo-name{
    position: absolute;
    left: 5vw;
    top: 49.5vh;
    width: 22.5vw;
    /* animation: aparition 4s; */
    z-index: 2;
}


.goLeft{
    animation: homeino 2.5s;
}
.goRight{
    animation: moverighto 2.5s;
}
/* ANIMACIONES 

/*Triangulo blanco del inicio*/
@keyframes homein {
    0% { left: -55vw;}
    100% { left: -15vw;}
}
@keyframes homeout {
    0% { left: -15vw;}
    100% { left: -55vw;}
}
/*Triangulo naranja del inicio*/
@keyframes homeino {
    0% { left: -50vw;}
    100% { left: -10vw;}
}
@keyframes homeouto {
    0% { left: -10vw;}
    100% { left: -50vw;}
}