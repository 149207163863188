*{
    margin: 0px;
    padding: 0px;
    font-family: "Andor", sans-serif;
   /* @font-face {
        font-family: Movicreto;
        src: url('AndorRegular-PersonalUse.ttf') format("truetype");
    } */
}
:root{
  --orange-color: #d5872c;
  --gray-color: #6c6e70;
}

html{
   /* font: Movicreto;*/
    scroll-behavior: smooth;
}


/***********************************************************************/

/*****/
.white-left{
    background-color: white;
    position: absolute;
    top: 0;
    width: 70vw;
    height: 90vh;
    transform: skewX(-20deg);
    left: -85vw;
    z-index: -1;
}

.white-right{
    background-color: white;
    position: absolute;
    top: 0;
    width: 70vw;
    height: 90vh;
    transform: skewX(20deg);
    left: 140vw;
    z-index: -1;
}
.white-right-end{
    background-color: var(--orange-color);
    position: absolute;
    display: flexbox;
    top: 0vh;
    width: 70vw;
    height: 45vh;
   /* transform: skewX(10deg);*/
    left: 140vw;
    z-index: 5;
    align-items: center;
    justify-content: center;
}

.white-right-end iframe{
    margin: 1vh;
    width: 59vw;
    height: 43vh; 
}

.fondo-textura {
    width: 100%;
    height: 100%;
    object-fit:cover;
    filter: opacity(30%);
}



.cover-logo{
    position: absolute;
    left: 10.7vw;
    top: 28vh;
    width: 11vw;
    animation: aparition 4s;
    z-index: 2;
}
.logo-end{
    position: absolute;
    left: 100vw;
    top: 0vh;
    width: 50vw;
    min-height: 90vh;
    z-index: -1;
    object-fit: fill;
}
.logo-textura {
    width: 100%;
    height: 100%;
    object-fit:fill;
}

@keyframes aparition {
    0% {    filter:opacity(0);}
    100% {    filter:opacity(1);}
}
@keyframes desaparition {
    0% {    filter:opacity(1);}
    100% {    filter:opacity(0);}
}
/**********************************************/

/*Diagonal blanca izquierda*/
@keyframes wl_right {
    0% { left: -85vw;}
    100% { left: -15vw;}
}
@keyframes wl_left {
    0% { left: -15vw;}
    100% { left: -85vw;}
}
/*Diagonal blanca derecha*/
@keyframes wr_left {
    0% { left: 140vw;}
    100% { left: 40vw;}
}
@keyframes wr_right {
    0% { left: 40vw;}
    100% { left: 140vw;}
}
/*Trapecio blanco arriba*/
@keyframes w_bot {
    0% { top: -90vh;}
    100% { top: 0vh;}
}
@keyframes w_top {
    0% { top: 0vh;}
    100% { top: -90vh;}
}
/***********LOGO**********/
@keyframes logoout {
    0%     {left: 10.7vw;
            top: 28vh;
            transform: scale(1);}
    100%   {left: 0vw;
            top: 0vh;
            transform: scale(0.6);}
}
@keyframes logoin {
    0%     {left: 0vw;
            top: 0vh;
            transform: scale(0.6);}
    100%   {left: 10.7vw;
            top: 28vh;
            transform: scale(1);}
}
@keyframes logo-namein {
    0%     {left: -5vw;
            top: 0vh;
            transform: scale(0);
            filter: opacity(0);}
    100%   {left: 5vw;
            top: 49.5vh;
            transform: scale(1);
            filter: opacity(1);}
}
@keyframes logo-nameout {
    0%     {left: 5vw;
            top: 49.5vh;
            transform: scale(1);
            filter: opacity(1);}
    100%   {left: -5vw;
            top: 0vh;
            transform: scale(0);
            filter: opacity(0);}
}
@keyframes logo-end-in {
    0% { left: -100vw;}
    100% { left: -6.5vw;}
}
@keyframes logo-end-out {
    0% { left: -6.5vw;}
    100% { left: -100vw;}
}
/*-----------Contenido de paginas---------------*/





.contactanos{
    background-color: var(--gray-color);
    color: white;
    display: none;
    flex-direction: column;
    position: absolute;
    left: 40vw;
    top: 45vh;
    width: 60vw;
    min-height:45vh ;
    align-items: center;
    /* border-radius: 5vh; */
}
.contactanos h1{
    font-size: 35px;
    /*padding-bottom: 5vh;
    padding-top: 5vh;*/
    text-align: center;
}
.contactanos h2{
    padding-top: 5vh;
    padding-bottom: 4vh;
    text-align: center;
}
.form-container{
    display:flex;
}
.form-container2{
    display:flex;
    flex-direction: column;
}
.form-container2 label{
    margin-bottom: 2vh;
}
.contactanos input{
    margin-left: 2vh;
    margin-right: 2vh;
   margin-bottom: 2vh;
    min-width: 10vw;
}
.form-container2 input{
    max-width: 10vh;
    margin-left: 30vh;
    min-height: 5vh;
}
.contactanos textarea{
    min-width: 40vw;
    max-width: 40vw;
    min-height: 10vh;
    max-height: 10vh;
    margin-bottom: 2vh;
}
/*-----------NAV----------------*/



@media only screen and (max-device-width: 980px){


  nav ul {
    display: flex;
    flex-direction: column;
}

nav ul li a {
  padding: 2vh 0 2vh;
}
}

@media only screen and (max-device-width: 980px){

    .contactanos{
        position: relative;
        display: block;
    }
    .nav-container{
        width:100%;
        position:-webkit-sticky;
    }
    nav ul {
        display: inline-block;
    }
    nav ul li{
        float:left;
        min-width: 25%;
    }
    nav ul li a {
        font-size: 2vh;
    }
    .white-right-end{
        display: none;
    }
    .white-right{
        display: none;
    }
    .white-left{
        display: none;
    }
}